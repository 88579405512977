<template>
  <div>
    <!-- Footer Info: Input Fields -->
    <b-form :style="{ height: trHeight }" class="repeater-form">
      <b-row>
        <!-- Field: Footer hide -->
        <b-col cols="12" md="4">
          <b-form-group label="Hide" label-for="hide">
            <b-form-checkbox switch id="hide" v-model="footerData.hide" />
          </b-form-group>
        </b-col>

        <!-- Field: background color -->
        <b-col cols="12" md="4">
          <b-form-group label="Background color" label-for="backgroundcolor">
            <b-form-input
              type="color"
              id="backgroundcolor"
              v-model="footerData.background_color"
            />
          </b-form-group>
        </b-col>

        <!-- Field: background image -->
        <b-col cols="12" md="4">
          <b-form-group label="Background image" label-for="backgroundimage">
            <b-form-file id="backgroundimage" v-model="footerData.bg_image" />
          </b-form-group>
        </b-col>
        <!-- Field: logo hide-->
        <b-col cols="12" md="4">
          <b-form-group label="Logo hide" label-for="logohide">
            <b-form-checkbox
              id="logohide"
              switch
              v-model="footerData.logo.hide"
            />
          </b-form-group>
        </b-col>

        <!-- Field: logo -->
        <b-col cols="12" md="4">
          <b-form-group label="Logo" label-for="logohref">
            <b-form-file id="logohref" v-model="footerData.logo_bg_image" />
          </b-form-group>
        </b-col>

        <!-- Field: detail -->
        <b-col cols="12" md="4">
          <b-form-group label="Detail" label-for="detail">
            <b-form-input id="detail" v-model="footerData.detail" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateFooter(footerData._id)"
    >
      Save Changes
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BFormFile,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    footerData: {
      type: Object,
      required: true,
    },
  },
  mixins: [heightTransition],
  data() {
    return {
      nextTodoId: 2,
    };
  },
  methods: {
    updateFooter(Id) {
      const form = new FormData();
      const data = this.footerData;
      console.log(data);
      form.append("hide", data.hide);
      form.append("detail", data.detail);
      form.append("logo_bg_image", data.logo_bg_image);
      form.append("logo_hide", data.logo.hide);
      form.append("background_color", data.background_color);
      store
        .dispatch("app-footer/updateFooter", { id: Id, footerData: form })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Footer updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
