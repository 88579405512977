<template>
  <component :is="footerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="footerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching footer data
      </h4>
      <div class="alert-body">
        No footer found with this footer id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-footers-list'}"
        >
          Footer List
        </b-link>
        for other footers.
      </div>
    </b-alert>

    <b-tabs
      v-if="footerData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ChevronRightIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Footer</span>
        </template>
        <footer-edit-tab-account
          :footer-data="footerData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <b-tab active>
        <template #title>
          <feather-icon
            icon="ChevronRightIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Links</span>
        </template>
        <footer-edit-first-level
          :footer-data="footerData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import footerStoreModule from '../footerStoreModule'
import FooterEditTabAccount from './FooterEditTabAccount.vue'
import FooterEditFirstLevel from './FooterEditFirstLevel.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    FooterEditTabAccount, 
    FooterEditFirstLevel,  
  },
  setup() {
    const footerData = ref(null)

    const FOOTER_APP_STORE_MODULE_NAME = 'app-footer'
    if (!store.hasModule(FOOTER_APP_STORE_MODULE_NAME)) store.registerModule(FOOTER_APP_STORE_MODULE_NAME, footerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(FOOTER_APP_STORE_MODULE_NAME)) store.unregisterModule(FOOTER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-footer/fetchFooter', { id: router.currentRoute.params.id })
      .then(response => { footerData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          footerData.value = undefined
        }
      })

    return {
      footerData,
    }
  },
}
</script>

<style>

</style>
