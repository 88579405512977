<template>
  <div>

    <!-- Footer Info: Input Fields -->
    <b-form
    :style="{height: trHeight}"
    class="repeater-form"
    >
     
      <!-- Row Loop first level-->
        <b-row
          v-for="(item, index) in footerData.links"
          :id="item._id"
          :key="item._id"
          ref="row"
        >
            <!-- Item title -->
            <b-col md="4">
                <b-form-group
                label="TITLE LEVEL I"
                label-for="item-title"
                >
                <b-form-input
                    id="item-title"
                    v-model="item.title"
                />
                </b-form-group>
            </b-col>
       
            <!-- Remove Button -->
            <b-col
                lg="2"
                md="3"
                class="mb-50"
            >
                <b-button
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                    >
                    <feather-icon
                        icon="XIcon"
                        class="mr-25"
                    />
                    <span>Delete</span>
                </b-button>
            </b-col>

        <!-- Row Loop second level-->
            <b-row
            v-for="(item, index2) in footerData.links[index].links"
            :id="item._id"
            :key="item._id"
            ref="row"
            class="second-level"
            >
          
                <!-- Item title -->
                <b-col md="3">
                    <b-form-group
                    label="Title Level II"
                    label-for="item-title"
                    >
                    <b-form-input
                        id="item-title"
                        v-model="item.title"
                    />
                    </b-form-group>
                </b-col>

                <!-- href -->
                <b-col md="3">
                    <b-form-group
                    label="href"
                    label-for="item-href"
                    >
                    <b-form-input
                        id="item-href"
                        v-model="item.href"
                    />
                    </b-form-group>
                </b-col>

                <!-- color -->
                <b-col md="2">
                    <b-form-group
                    label="color"
                    label-for="item-color"
                    >
                    <b-form-input
                        id="item-color"
                        v-model="item.color"
                    />
                    </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                >
                    <b-button
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem2(index, index2)"
                        >
                        <feather-icon
                            icon="XIcon"
                            class="mr-25"
                        />
                        <span>Delete</span>
                    </b-button>
                </b-col>      

            </b-row>
            
            
              <b-col md="4"> </b-col>
              <b-col md="4" class="margin-button">
                  <b-button
                  variant="outline-warning"
                  @click="repeateAgain2(index)"
                  >
                      <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                      />
                      <span>Add level II</span>
                  </b-button> 
              </b-col>
              
           

            <b-col cols="12">
            <hr>
            </b-col>

        </b-row>

    </b-form>

    <b-row>
        <b-button
        variant="outline-info"
        @click="repeateAgain"
        >
        <feather-icon
            icon="PlusIcon"
            class="mr-25"
        />
        <span>Add level I</span>
        </b-button>
        
        <span>*** After, save and refresh page to add level II...</span>
    </b-row>
    <br>
    <br>
    <b-row>
        <!-- Action Buttons -->
        <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="updateFooter(footerData._id)"
        >
        Save Changes
        </b-button>
    </b-row>
    
  </div>
</template>



<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,

  },
  props: {
    footerData: {
      type: Object,
      required: true,
    },
  },
    mixins: [heightTransition],
  data() {
    return {
      optionsHide: [
        { value: true, text: 'True' },
        { value: false, text: 'False' }
      ],
      nextId: 2,
      nextId2: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
   repeateAgain() {
      this.footerData.links.push({
        id: this.nextId += this.nextId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.footerData.links.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
       // this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },

    repeateAgain2(index) {
      this.footerData.links[index].links.push({
        id: this.nextId2 += this.nextId2,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem2(index, index2) {
      this.footerData.links[index].links.splice(index2, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    
    updateFooter(Id) {  
      store.dispatch('app-footer/updateFooter', { id: Id, footerData: this.footerData})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Footer updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.second-level {
  margin-left: 4%;
}
.margin-button {
  margin-left: 20%;
}
</style>
